import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import reason from './modules/reason'
import answer from './modules/answer'
import dh_device from "./modules/dh_dervice";
import getters from './getters'
import socketDh from './modules/socketDh'
import socketHm from './modules/socketHm'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        reason,
        answer,
        dh_device,
        socketDh,
        socketHm,
    },
    state: {

    },
    mutations: {

    },
    actions: {

    },
    getters
})
