import { notification, message } from "ant-design-vue";
import moment from 'moment'

const socketDh = {
  state: {
    dhWeb: null,

    dhState: 'info',
    dhMsg: '',
    onLineDH: false,
    dhLoginHandle: null,

    dhCalls: [],

    accountError: false,
  },
  getters: {
    dhCalls: state => state.dhCalls,
    onLineDH: state => state.onLineDH,
    dhState: state => state.dhState,
    dhMsg: state => state.dhMsg,
    dhWeb: state => state.dhWeb,
    dhLoginHandle: state => state.dhLoginHandle,
  },
  mutations: {
    SET_DHWEB(state, dhWeb) {
      state.dhWeb = dhWeb;
    },
    SET_STATE_INFO(state, { state: paramsState, message }) {
      state.dhState = paramsState;
      state.dhMsg = message;
    },
    SET_LOGIN_HANDLE(state, handle) {
      state.dhLoginHandle = handle;
    },
    SET_DH_CALLS(state, calls) {
      const statusMap = {}
      const filterCall = calls.filter((item) => {
        if(statusMap[item.deviceNo]){
          return false
        }else{
          statusMap[item.deviceNo] = true
          return true
        }
      })
      state.dhCalls = filterCall;
    },
    SET_ONLINE_DH(state, online) {
      state.onLineDH = online;
    },
    SET_ACCOUNT_ERROR(state, bol) {
      state.accountError = bol;
    }
  },
  actions: {
    initDh({ state, commit, dispatch }){
      // eslint-disable-next-line no-undef
      const dhWeb = new DHAlarmWeb()
      dhWeb.setWebsocketPort({ dataWsPort: 443, mediaWsPort: 443 })
      dhWeb.onNotify = (data) => {
        const params = data.params;
        if (params.code === 'DeviceStatus') {
          if (params.action === 'Start') {
            const deviceNo = params.deviceId
            dispatch('addNewCall', deviceNo).then(res => {
              commit('SET_DH_CALLS', [...state.dhCalls, res])
              notification.open({
                message: res.parkingName + '(' + res.channelName + ')',
                description: '有新的呼叫 请及时处理',
                placement: 'bottomRight',
                // icon: <a-icon type="phone" theme="twoTone" />,
                duration: 3,
                key: res.deviceNo
              })
            }).catch((err) => {
              message.error('新增呼叫异常')
              const callTime = moment.now()
              commit('SET_DH_CALLS', [...state.dhCalls, { parkingName: '未知停车场',channelName: '未知通道', deviceNo, callTime }])
            })
          } else if(params.action === 'Dealing' || params.action === 'Normal') {
            const deviceNo = params.deviceId;
            notification.close(deviceNo + '')
            let cacheDhCalls = [ ...state.dhCalls ]
            const dhCalls = cacheDhCalls.filter(item => {
              return Number.parseInt(item.deviceNo) !== deviceNo
            })
            commit('SET_DH_CALLS', dhCalls)
            dispatch('setDHDevices', params)
          }
        }
      }
      dhWeb.onLogin = (data) => {
        dispatch('dhOnLogin', data)
      }
      dhWeb.onPlayRT = (e) => {
        if (e.error !== 'success') {
          notification.error({
            placement: 'bottomLeft',
            message: '播放失败',
            description: '设备不在线或网络不佳',
            duration: null
          })
        }
      }
      dhWeb.onAlarmServerClosed = () => {
        commit('SET_STATE_INFO', { state: 'warn', message: '连接断开,5秒后将尝试自动登录' })
        try {
          dhWeb.logout(state.dhLoginHandle);
        } catch (e) {
          console.error('登出异常')
          console.error(e)
        } finally {
          commit('SET_LOGIN_HANDLE', undefined)
          commit('SET_DH_CALLS', [])
          commit('SET_ONLINE_DH', false)
        }
      }
      dhWeb.onDeviceList = (e) => {
        dispatch('setDHDevices', e.params.list[0])
      }
      commit('SET_DHWEB', dhWeb);
      dispatch('loginDh')
    },
    loginDh({ state, rootState, commit, dispatch }){
      const userInfo = rootState.user.info
      if (userInfo && userInfo.dihuUsername && userInfo.dihuPassword) {
        commit('SET_STATE_INFO', { state: 'info', message: '正在登录...' })
        state.dhWeb.login(userInfo.dihuUsername, userInfo.dihuPassword, 'dh.hmzhtc.com');
      } else {
        commit('SET_STATE_INFO', { state: 'error', message: 'DH账号未配置' })
        return
      }
      // 获取20-30的随机数
      const random = Math.floor(Math.random() * 11 + 20) * 1000
      setInterval(() => {
        if (!state.dhLoginHandle && !state.accountError) {
          dispatch('loginDh')
        }
      }, random);
    },
    dhOnLogin ({ commit }, data) {
      if(data.error === 'success') {
        notification.success({
          message: '登录DH成功',
          duration: 3
        })
        commit('SET_ONLINE_DH', true)
        commit('SET_LOGIN_HANDLE', data.params.loginHandle)
        commit('SET_STATE_INFO', { state: 'success', message: '登录DH成功' })
      } else if (data.error === 'repeat') {
        notification.error({
          message: 'DH登录失败',
          description: '该坐席DH账号已经登录，不能重复登录',
          duration: null
        });
        commit('SET_STATE_INFO', { state: 'error', message: '登录DH失败，重复登录' })
        return
      } else if(data.error == 'authfail'){
        notification.error({
          message: 'DH登录失败',
          description: 'DH账号或密码错误',
          duration: null
        });
        commit('SET_ACCOUNT_ERROR', true)
        commit('SET_STATE_INFO', { state: 'error', message: '登录DH失败，账号或密码错误' })
        return
      } else {
        notification.error({
          message: 'DH登录失败',
          duration: null
        });
        commit('SET_STATE_INFO', { state: 'error', message: '登录DH失败，未知错误' })
        return
      }
    },
    dhLogout ({ state, commit }) {
      try {
        state.dhWeb.logout(state.dhLoginHandle);
      } catch (e) {
        console.error('登出异常')
        console.error(e)
      } finally {
        commit('SET_LOGIN_HANDLE', undefined)
        commit('SET_DH_CALLS', [])
        commit('SET_ONLINE_DH', false)
      }
    },
  },
};
export default socketDh;