import { notification } from "ant-design-vue";
import moment from "moment";
import storage from 'store'
import store from "@/store";

const fixedUrl = `${location.host}/api/unattended/websocket/`
let socketUrl = ''
if(location.protocol === 'https:'){
  socketUrl = 'wss://' + fixedUrl
}else{
  socketUrl = 'ws://' + fixedUrl
}


const socketHm = {
  state: {
    socketInstance: null,
    onLine: false,

    // 车行呼叫
    hmCalls: [],
    playEvent: () => {},

    // 人行呼叫
    accessHmCalls: [],
    accessPlayEvent: () => {},
    
    timer: null,
    captureEvent: null,
  },
  getters: {
    hmCalls: state => state.hmCalls,

    onLine: state => state.onLine,

    accessHmCalls: state => state.accessHmCalls,
    captureAction: state => state.captureEvent,
  },
  mutations: {
    SET_SOCKET_INSTANCE: (state, instance) => {
      state.socketInstance = instance;
    },
    SET_ONLINE: (state, online) => {
      state.onLine = online;
    },

    // 车行呼叫
    SET_PLAY_EVENT: (state, event) => {
      state.playEvent = event;
    },
    SET_HM_CALLS: (state, calls) => {
      state.hmCalls = calls;
    },

    // 人行呼叫
    SET_ACCESS_PLAY_EVENT: (state, event) => {
      state.accessPlayEvent = event;
    },
    SET_ACCESS_HM_CALLS: (state, calls) => {
      state.accessHmCalls = calls;
    },
    
    SET_TIMER: (state, timer) => {
      state.timer = timer;
    },
    SET_CAPTURE_EVENT: (state, event) => {
      state.captureEvent = event;
    }
  },
  actions: {
    closeWsInstance({ state }){
      if (state.socketInstance) {
        state.socketInstance.close()
        state.socketInstance = null
      }
    },
    initWebsocket({ commit, state, rootState, dispatch }, isForce=false) {
      if (!('WebSocket' in window)) {
        alert('当前浏览器 Not support websocket')
        return
      }
      if (state.onLine) {
        if (state.socketInstance) {
          // 彻底关闭websocket连接，防止内存泄漏
          state.socketInstance.close()
          state.socketInstance = null

        }
        commit("SET_HM_CALLS", []);
        commit('SET_ONLINE', false);
      } else {
        let url = socketUrl + rootState.user.info.userid + `/${isForce ? 'true' : 'false'}` + "?access-token=" + storage.get("token");
        if (state.socketInstance) {
          state.socketInstance.close()
          state.socketInstance = null

        }
        let socket = new WebSocket(url);
        socket.onopen = () => {
          dispatch('heartBeat')
          window.onbeforeunload = () => {
            dispatch('onbeforeunload')
          }
          console.debug('WebSocket连接成功,状态码：' + socket.readyState)
        }
        socket.onerror = () => {
          console.error('WebSocket连接发生错误,状态码：' + socket.readyState)
        }
        socket.onmessage = (event) => {
          dispatch('disposeMessageEvent' ,event)
        }
        socket.onclose = () => {
          socket.onclose = socket.onerror = socket.onmessage = null
          console.error('WebSocket连接关闭,状态码：' + socket.readyState)
          socket = null
          commit('SET_ONLINE', false)
          commit('SET_HM_CALLS', [])
          commit('SET_ACCESS_HM_CALLS', [])
        }
        commit('SET_SOCKET_INSTANCE', socket);
        commit('SET_ONLINE', true);
      }
      // 页面关闭，关闭websocket
      window.onbeforeunload = () => {
        if (state.socketInstance) {
          state.socketInstance.close()
          state.socketInstance = null
        }
      }
    },
    onbeforeunload({ state, commit }) {
      commit('SET_ONLINE', false)
      if (this.websocket) {
        this.websocket.close(1000)
      }
      if (state.timer) {
        clearInterval(state.timer)
      }
    },
    heartBeat({ state, commit, dispatch }) {
      const random = Math.floor(Math.random() * 10 + 20) * 100 + 20000
      if(state.timer){
        clearInterval(state.timer)
      }
      commit('SET_TIMER', setInterval(() => {
        if (state.onLine) {
          if(!state.socketInstance || state.socketInstance.readyState !== 1) {
            dispatch('initWebsocket')
          } else {
            state.socketInstance.send(JSON.stringify({type: 'HEARTBEAT' }))
          }
        } else {
          dispatch('initWebsocket')
        }
      }, random))
    },
    disposeMessageEvent({ commit, state, dispatch }, event) {
      console.debug('服务端返回：' + event.data)
      const res = JSON.parse(event.data)
      if(res.type === 'CALLS') {
        const hmCalls = []
        const accessHmCalls = []
        res.data.forEach(item => {
          if (item.callType === 1) {
            hmCalls.push(item)
          } else if(item.callType === 2) {
            accessHmCalls.push(item)
          }
        })
        commit('SET_HM_CALLS', hmCalls)
        commit('SET_ACCESS_HM_CALLS', accessHmCalls)
      }else if(res.type === 'LOGGED'){
        const currentKey = new Date().getTime()
        notification['error']({
          message: '手机呼叫登录失败',
          key: currentKey,
          description: '该坐席账号已经登录手机呼叫服务器，不能重复登录',
          duration: 5,
          btn: h => {
            return h(
              'a-button',
              {
                props: {
                  type: 'primary',
                  size: 'middle',
                },
                on: {
                  click: () => {
                    notification.close(currentKey)
                    dispatch('initWebsocket', true)
                  },
                },
              },
              '强制登陆',
            );
          }
        });
        commit('SET_ONLINE', false)
      }else if(res.type === 'LOGIN_SUCCESSFUL') {
        notification['success']({
          message: '手机登录成功',
          description: '成功连接手机呼叫服务器',
          duration: 3
        })
      } else if (res.type === 'PARKING_DISCONNECTION') {
        notification['warning']({
          message: res.Data?.parkingName + '断线线请及时处理',
          description: '断线时间：' + moment(res.Data?.connected_at).format('YYYY-MM-DD HH:mm:ss') + ' 联系人：' + res.Data?.contacts +' 联系电话：' + res.Data?.telephone,
          duration: null
        })
      }
      if (res.type === 'HANGUP') {
        const deviceNo = res.deviceNo
        const parkingCalls = state.hmCalls.filter(item => {
          return item.deviceNo !== deviceNo
        })
        // debugger
        commit('SET_HM_CALLS', parkingCalls)
        const accessCalls = state.accessHmCalls.filter(item => {
          return item.deviceNo !== deviceNo
        })
        commit('SET_ACCESS_HM_CALLS', accessCalls)
      }
      if(res.type === 'ANSWER'){
        const deviceNo = res.deviceNo
        const calls = state.hmCalls.filter(item => {
          return item.deviceNo !== deviceNo
        })
        const hmCalls = state.accessHmCalls.filter(item => {
          return item.deviceNo !== deviceNo
        })
        commit('SET_HM_CALLS', calls)
        commit('SET_ACCESS_HM_CALLS', hmCalls)
      }
      if(res.callType === 1){
        if (res.type === 'CALL') {
          commit('SET_HM_CALLS', [ ...state.hmCalls, res.data ])
          new Notification('新的呼叫', {
            body: '您有新的车行呼叫等待处理',
          });
  
        }
        if ((res.type === 'CALLS' || res.type === 'CALL') && state.hmCalls !== null && state.hmCalls.length > 0){
          if(state.playEvent){
            state.playEvent()
          }
        }
      }else if(res.callType === 2){
        if (res.type === 'CALL') {
          commit('SET_ACCESS_HM_CALLS', [ ...state.accessHmCalls, res.data ])
          new Notification('新的呼叫', {
            body: '您有新的人行呼叫等待处理',
          });
        }
        if ((res.type === 'CALLS' || res.type === 'CALL') && state.accessHmCalls !== null && state.accessHmCalls.length > 0){
          if(state.accessPlayEvent){
            state.accessPlayEvent()
          }
        }
      }
    }
  },
}
export default socketHm;