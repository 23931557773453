import request from "../utis/request";

const callApi = {
    userSig : '/unattended/trtc/getUserSig',
    call: '/unattended/hm-call/call',
    answer: '/unattended/hm-call/answer',
    hangUp: '/unattended/hm-call/hang-up',
    missedCall: '/unattended/record/missed-call',
}

export function getUserSig () {
    return request({
        url: callApi.userSig,
        method: 'GET'
    })
}

export function hmCall (deviceNo) {
    return request({
        url: callApi.call + '/' + deviceNo,
        method: 'POST'
    })
}

export function hmAnswer (params) {
    return request({
        url: callApi.answer,
        method: 'POST',
        data: params
    })
}

export function hmHangUp (params) {
    return request({
        url: callApi.hangUp,
        method: 'POST',
        data: params
    })
}
export function missedCall (params) {
    return request({
        url: callApi.missedCall,
        method: 'get',
        params
    })
}
