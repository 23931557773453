<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import { notification } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default {
  name: 'Home',
  components: {
  },
  data: function() {
    return {
      height: 0,
      locale: zhCN
    };
  },
  methods: {
  },
  created() {
    this.height = document.body.clientHeight - 67
    // if ('Notification' in window) {
    //   // 请求权限，这将展示一个系统提示，询问用户是否允许通知
    //   Notification.requestPermission()
    // } else {
    //   alert('您的浏览器不支持通知');
    // }
  }
}
</script>
<style>
#app {
  width: auto;
  height: 100%;
}
</style>
